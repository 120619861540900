import { graphql, Link } from "gatsby";
import React from "react";
import * as s from "../styles/blog-post.module.css";
import CategoriesMenu from "./../components/blog/categoriesMenu/categoriesMenu";
import SubscribeBlockBlog from "./../components/blog/subscribeBlockBlog/subscribeBlockBlog";
import Layout from "./../components/layout";
import Post from "./../components/post/post";
import Seo from "./../components/seo";
import Title from "./../components/title/title";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPage },
}) => {
  const bp = useBreakpoint();
  const posts = data.allWpPost.nodes;
  const site = data.site;

  let title = "All posts";
  let canonical = site.siteMetadata.siteUrl + '/blog/'
  if (currentPage > 1) {
    title = `All posts - Page ${currentPage}`
    canonical = `${site.siteMetadata.siteUrl}/blog/${currentPage}/`
  }
  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title={title} links={[{
          rel: 'canonical',
          href: canonical
        }]} />
        <div className="container">
          <SubscribeBlockBlog />
          <Title>Blog</Title>
        </div>
        <CategoriesMenu />
        <div className="container">
          <p>
            No blog posts found. Add posts to your WordPress site and they'll
            appear here!
          </p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout isHomePage>
      <Seo title={title} links={[{
        rel: 'canonical',
        href: canonical
      }]} />
      <div className="container">
        <Title h1={true}>Blog</Title>
      </div>
      <CategoriesMenu />
      <div className="container">
        <div className={`row v7articlesWrapper`}>
          {posts.map(post => {
            // const title = post.title
            return (
              <div className="col-md-6 col-lg-4 mb-3" key={post.uri}>
                <Post post={post} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="container">
        <div className={s.singleNavigation}>
          {previousPagePath && (
            <Link to={previousPagePath} rel="prev">
              Previous page
            </Link>
          )}
          {nextPagePath && (
            <Link to={nextPagePath} rel="next">
              Next page
            </Link>
          )}
        </div>
      </div>
      <div className="container">
        <SubscribeBlockBlog />
      </div>
      <section></section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    site {
      siteMetadata {
        title: title
        siteUrl: url
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        readingTime {
          text
        }
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData( quality: 80 )
              }
            }
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;
